import React from 'react';
import HomePage from './routes/home-page'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import ContactUs from './routes/contact-us';
import Pricing from './routes/pricing';
import Services from './routes/services';
import TsCs from './routes/TsCs';

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/services" element={<Services />} />
          <Route path="/terms-and-conditions" element={<TsCs />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;