import React, { useRef, useEffect } from 'react';
import '../css/home-service-ad-imgR.css';
import { Link } from 'react-router-dom';

function ServiceAdImgR(props) {
    const componentRef = useRef(null);

    useEffect(() => {
          const component = componentRef.current;
  
          const handleScroll = () => {
          const top = component.getBoundingClientRect().top;
          const windowHeight = window.innerHeight;
          if (top < windowHeight * 0.55) {
              component.classList.add('fade-in-active');
              window.removeEventListener('scroll', handleScroll);
          }
          };
  
          window.addEventListener('scroll', handleScroll);
          handleScroll();
    }, []);
  
    return (
        <div ref={componentRef} className="fade-in">
            <div className='service-ad-container-R'>
                <div className='service-ad-flex-R'>
                    <div className='service-ad-text-R'>
                        <div className='linked-header-R'>
                            <Link to = '/services' style={{textDecoration: "none"}}>
                                <h3>
                                    {props.header}
                                </h3>
                            </Link>
                        </div>
                        <p style={{paddingBottom: "0px"}} className='home-service-paragraph-R'>
                            {props.paragraph}
                        </p>
                        <div className='learn-more-R'>
                            <Link to = '/services' style={{textDecoration: "none"}} className='learn-more-link-R'>
                                <p className='learn-more-p-R' style={{textDecoration:"underline"}}>&#62; Learn more</p>
                            </Link>
                        </div>
                    </div>
                    <div className='service-ad-img-R'>
                        <img src={props.img} alt="placeholder" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceAdImgR;
