import React from 'react';
import '../css/logo-banner.css';

function HeaderBanner(props) {
  
    return (
        <div style={{height:"210px", display:"flex", justifyContent:"center", alignContent:"center"}} className='logo-banner-container'>
            <div style={{margin:"0", display:"flex", alignItems:"center"}} className='logo-banner-header'>
                <h1 className={props.headerClass}>
                    <span className='Lens'>{props.header}</span>
                </h1>
            </div>
        </div>
    )
}

export default HeaderBanner;