import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser'
import '../css/contact-form.css';

function ContactForm() {
    const formRef = useRef();

    const [emailError, setEmailError] = useState('');

    const serviceID = 'default_service';
    const templateID = 'template_9f0pdqo';
    const userID = 'nfxxiV-86hSf1lXjI';

    function sendEmail(e) {
        e.preventDefault();
        const email = e.target.email.value;

        if (/\S+@\S+\.\S+/.test(email)) {
            try {                
                    emailjs.sendForm(serviceID, templateID, e.target, userID)
                        .then((result) => {
                            console.log(result.text);
                        }, (error) => {
                            console.log(error.text);
                        })
                setEmailError('');
                console.log("email sent")
                alert('Submitted successfully!')
            } catch (error) {
                console.error(error.message);
                setEmailError('Failed to send email. Please try again.');
            }
            } 
        else {
            setEmailError('* Please enter a valid email.');
        }

        formRef.current.reset();
    }

    return (
        <div className='contact-form-container'>
        <div className='form-container'>
            <h5 className='contact-info'>Fill out the form or email us at:</h5>
            <h5 className='contact-email'>contact@metalens.com.au</h5>
            <form className='contact-form' ref={formRef} onSubmit={sendEmail} id="contact-form">
                <input className='name-input' type='name' name='name' id='name' required='required' placeholder='Name' autoComplete='on'></input>
                {emailError === '' ? 
                    <input className='email-input' type='email' name='email' id='email' required='required' placeholder='Email' autoComplete='on'></input> 
                    : <input className='email-input' type='email' name='email' id='email' required='required' placeholder='Email' autoComplete='on' style={{border:"2px solid red"}}></input>
                }
                {emailError && <p className='error' style={{color:"red", margin:"0", marginBottom:"10px"}}>{emailError}</p>}
                <label htmlFor='subject'>Subject:</label>
                <select id='subject' name='subject'>
                    <option value='General Enquiry'>General Enquiry</option>
                    <option value='Quote'>Request a Quote</option>
                </select>
                <textarea className='text-input' placeholder='Message' name='message' id='text' required='required'></textarea>
                <div className='button-container'>
                    <button className='submit-button' type='submit'>submit</button>
                </div>
            </form>
        </div>
        </div>
    )
}

export default ContactForm;
