import React, { useRef, useEffect } from 'react';
import '../css/home-service-ad-imgL.css';
import { Link } from 'react-router-dom';

function ServiceAdImgL(props) {
  const componentRef = useRef(null);

  useEffect(() => {
        const component = componentRef.current;

        const handleScroll = () => {
        const top = component.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        if (top < windowHeight * 0.55) {
            component.classList.add('fade-in-active');
            window.removeEventListener('scroll', handleScroll);
        }
        };

        window.addEventListener('scroll', handleScroll);
        handleScroll();
  }, []);

  return (
    <div ref={componentRef} className="fade-in">
        <div className='service-ad-container'>
            <div className='service-ad-flex'>
                <div className='service-ad-img'>
                    <img src={props.img} alt="placeholder" />
                </div>
                <div className='service-ad-text'>
                    <div className='linked-header'>
                        <Link to = '/services' style={{textDecoration: "none"}}>
                            <h3>
                                {props.header}
                            </h3>
                        </Link>
                    </div>
                    <p style={{paddingBottom: "0px"}} className='home-service-paragraph'>
                        {props.paragraph}
                    </p>
                    <div className='learn-more'>
                        <Link to = '/services' style={{textDecoration: "none"}} className='learn-more-link'>
                            <p className='learn-more-p' style={{textDecoration:"underline"}}>&#62; Learn more</p>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ServiceAdImgL;
