import React from 'react';
import '../css/footer.css';
import { Link } from 'react-router-dom';

function Footer() {
  
    return (
        <div className='footer-container'>
            <div className='footer-text'>
                <li className='TsCs'><Link to="/terms-and-conditions" style={{textDecoration:"none"}}><h5>T's & C's</h5></Link></li>
                <span class='vertical-line'></span>
                <h5>ABN: 32 839 257 509</h5>
            </div>
            <div className='author'>
                <h5>Website designed and built by <a href="https://www.linkedin.com/in/chloe-hulme/">Chloe Hulme</a></h5>
            </div>
        </div>
    )
}

export default Footer;
