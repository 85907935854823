import React, {useEffect} from 'react';
import HeaderContactUs from '../components/header-props';
import HeaderBanner from '../components/header-banner';
import ContactForm from '../components/contact-form';
import Footer from '../components/footer';

import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'G-6K4L3QFC9E'
}

TagManager.initialize(tagManagerArgs)

function ContactUs() {
 
    window.dataLayer.push({
        event: 'pageview'
    });
    
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <HeaderContactUs 
                first_link = 'Services'
                first_link_to = '/services'
                second_link = 'Pricing'
                second_link_to = '/pricing'
            />
            <HeaderBanner 
                header = 'Contact Us'
            />
            <ContactForm />
            <Footer />
        </div>
    );
}

export default ContactUs;
