import React from 'react';
import '../css/about-us.css';
import ChloeHeadshot from '../images/chloe-headshot.png'; 
import LukeHeadshot from '../images/luke-headshot.png'; 

function AboutUs() {
  
    return (
        <div className='about-us-container'>
            <div className='about-us-banner'>
                <h3>About Us</h3>
            </div>
            <div className='about-us-p-container'>
                <p>
                    MetaLens was founded with a vision to redefine the world of real estate by revolutionising 
                    the way properties are experienced and presented. Leveraging cutting-edge technology, 
                    we&#39;re able to create captivating virtual tours that increase exposure and accessibility 
                    to real estate <span style={{fontWeight: "bold"}}>like never before. </span>
                    <br></br>
                    <br></br>
                    This is complemented by our suite of other services 
                    which include property photography, landscape captures and detailed floorplans, 
                    all underpinned by a foundation of affordability in an ever challenging real estate environment.
                </p>
                <div className='about-img-container'>
                    <div className='img-headshot chloe-headshot'>
                        <img src={ChloeHeadshot} alt="Chloe headshot" />
                        <h5>Chloe Hulme | Director</h5>
                    </div>
                    <div className='img-headshot luke-headshot'>
                        <img src={LukeHeadshot} alt="Luke headshot" />
                        <h5>Luke Hwang | Director</h5>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUs;
