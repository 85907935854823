import React from 'react';
import '../css/home-contact-banner.css';

function HomeContactBanner() {
  
    return (
        <div className='home-contact-banner-container'>
            <div className='home-contact-banner-text'>
                <h3>
                    Want to showcase your property in <span className='fourK'>4K</span>?
                </h3>
                <h2>
                    Contact us below...
                </h2>
            </div>
        </div>
    )
}

export default HomeContactBanner;
