import React, {useEffect} from 'react';
import HeaderContactUs from '../components/header-props';
import Footer from '../components/footer';
import TsCsInfo from '../components/ts-cs';
import HeaderBanner from '../components/header-banner';

import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'G-6K4L3QFC9E'
}

TagManager.initialize(tagManagerArgs)

function TsCs() {
    
    window.dataLayer.push({
        event: 'pageview'
    });

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <HeaderContactUs 
                first_link = 'Services'
                first_link_to = '/services'
                second_link = 'Pricing'
                second_link_to = '/pricing'
            />
            <HeaderBanner 
                header = 'Terms & Conditions'
                headerClass = 'TsCs'
            />
            <TsCsInfo />
            <Footer />
        </div>
    );
}

export default TsCs;
