import React from 'react';
import '../css/ts-cs.css';

function TsCsInfo() {
  
    return (
        <div className='TsCs-container'>
            <p>
                <h3>General</h3>
                These terms and conditions ("Terms") govern the provision of virtual tour scanning services ("Services") by MetaLens ("Company") to the customer ("Customer").
                By engaging the Company to provide the Services, the Customer agrees to be bound by these Terms.
                <br></br>
                <br></br>
                <h3>Services</h3>
                The Company will provide the Services to the Customer as described in the agreed-upon proposal or contract.
                The Company will use reasonable efforts to ensure that the Services are provided in a timely and professional manner.
                The Company reserves the right to decline any request for Services.
                <br></br>
                <br></br>
                <h3>Customer Obligations</h3>
                The Customer must provide access to the property or properties to be scanned, as well as any necessary permissions or authorisations.
                The Customer must ensure that the property or properties are safe for the Company's staff to enter and scan.
                The Customer is responsible for providing accurate and complete information to the Company about the property or properties to be scanned.
                <br></br>
                <br></br>
                <h3>Payment</h3>
                The Customer agrees to pay the Company the fees for the Services as set out in the agreed-upon quote.
                Payment is due upon completion of the Services, unless otherwise agreed in writing.
                In the event of non-payment, the Company reserves the right to withhold or suspend further provision of Services.
                <br></br>
                <br></br>
                <h3>Intellectual Property</h3>
                The Customer acknowledges that the Company owns all intellectual property rights in the virtual tour scans of the property or properties created by the Company under these Terms.
                The Customer may use the virtual tour scans for its own personal or business purposes, but may not sell or distribute the virtual tour scans to any third party without the prior written consent of the Company.
                <br></br>
                <br></br>
                <h3>Limitation of Liability</h3>
                The Company will not be liable to the Customer for any loss, damage or expense arising out of or in connection with the provision of the Services, except to the extent that such loss, damage or expense is caused by the gross negligence or willful misconduct of the Company.
                The liability of the Company to the Customer for any claim arising out of or in connection with the provision of the Services will be limited to the fees paid by the Customer for the Services.
                <br></br>
                <h3>Confidentiality</h3>
                The Company will keep confidential any information provided by the Customer in connection with the provision of the Services, except to the extent that such information is required to be disclosed by law or by a regulatory authority.
                <br></br>
                <br></br>
                <h3>Termination</h3>
                Either party may terminate these Terms by giving written notice to the other party.
                In the event of termination, the Customer will pay the Company for all Services provided up to the date of termination.
                <br></br>
                <br></br>
                <h3>Governing Law and Jurisdiction</h3>
                These Terms will be governed by and construed in accordance with the laws of Australia.
                Any dispute arising out of or in connection with these Terms will be subject to the non-exclusive jurisdiction of the courts of Australia.
                <br></br>
                <br></br>
                <h3>Entire Agreement</h3>
                These Terms constitute the entire agreement between the parties and supersede all prior agreements, understandings and representations, whether written or oral, relating to the subject matter of these Terms.
                <br></br>
                <br></br>
                No variation of these Terms will be binding unless agreed in writing by both parties.
            </p>
        </div>
    )
}

export default TsCsInfo;