import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../css/header-home.css';
import Logo from '../images/logo.png'; 

function HeaderContactUs(props) {
    const [menuOpen, setMenuOpen] = useState(false);

    const handleMenuToggle = () => {
        setMenuOpen(prevState => !prevState);
    };
  
    return (
        <header class="header-home-container not-home-container">
            <nav>
                <div className="nav-wrapper">

                <div className={`hamburger ${menuOpen ? 'open' : ''}`} onClick={handleMenuToggle}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <ul className={`nav-links ${menuOpen ? 'open' : ''}`}>
                    <li><Link to = '/' className='header-links-home'><img src={Logo} alt="MetaLens Logo" /></Link></li>
                    <li><Link to = {props.first_link_to} className='header-links'>{props.first_link}</Link></li>
                    <li><Link to = {props.second_link_to} className='header-links'>{props.second_link}</Link></li>
                </ul>
                </div>
            </nav>
            <div className={`offer-banner ${menuOpen ? 'hide-banner' : ''}`}>
                <p>Refer a client and both get <span className="accent-color">15% OFF</span> your next booking with us!</p>
            </div>
        </header>
    )
}

export default HeaderContactUs;
