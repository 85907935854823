import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../css/header-home.css';

function HeaderHome() {
    const [menuOpen, setMenuOpen] = useState(false);

    const handleMenuToggle = () => {
        setMenuOpen(prevState => !prevState);
    };

    return (
        <header className="header-home-container">
            <nav>
                <div className="nav-wrapper">
                <div className="logo">Your Logo Here</div>
                <div className={`hamburger ${menuOpen ? 'open' : ''}`} onClick={handleMenuToggle}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <ul className={`nav-links ${menuOpen ? 'open' : ''}`}>
                    <li><Link to="/contact" className="header-links-contact">Contact Us</Link></li>
                    <li><Link to="/services" className="header-links">Services</Link></li>
                    <li><Link to="/pricing" className="header-links">Pricing</Link></li>
                </ul>
                </div>
                <div class="meta"></div>
                <div class="lens"></div>
            </nav>
            <div className={`offer-banner ${menuOpen ? 'hide-banner' : ''}`}>
                <p>Refer a client and both get <span className="accent-color">15% OFF</span> your next booking with us!</p>
            </div>
            </header>
    );
}

export default HeaderHome;
