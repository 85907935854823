import React from 'react';
import ServicesInfoComp from './services-info-comp';
import '../css/services-info.css';
import PropPhotog from '../images/prop-photog-1.jpg'
import Landscape from '../images/landscape-1.jpg'
import Dollhouse from '../images/dollhouse-1.jpeg'
import Floorplan from '../images/floorplan-2.png'
// import {Carousel} from '3d-react-carousal';

function ServicesInfo() {

    // let slides = [
    //     <img  src="https://picsum.photos/800/300/?random" alt="1" />,
    //     <img  src="https://picsum.photos/800/301/?random" alt="2" />  ,
    //     <img  src="https://picsum.photos/800/302/?random" alt="3" />  ,
    //     <img  src="https://picsum.photos/800/303/?random" alt="4" />  ,
    //     <img  src="https://picsum.photos/800/304/?random" alt="5" />   ];


    return (
        <div className='srvs-info-container'>
            {/* <div className='gallery-container'>
                <Carousel slides={slides} autoplay={true} interval={4000}/>
            </div> */}
            <div className='srvs-info-inner-container'> 
                <ServicesInfoComp 
                    service = "Virtual Tours"
                    description = "Enable others to virtually discover your property with our advanced virtual tour captures. Our specialised team use advanced technology to transform spaces into captivating digital worlds, which allow users to tour and navigate from the comfort of their devices. Whether you are showcasing real estate, architectural marvels or stunning venues, our virtual tours redefine how people interact with and explore space."
                    listTitle = "Our virtual tours are perfect for showcasing:"
                    first = "Real estate &#45; residential and commercial"
                    second = "Hotels"
                    third = "Fitness centres"
                    fourth = "Restaurants & Bars"
                    fifth = "Sporting venues"
                    descriptionClass = "first"
                    img = {Dollhouse}
                />
                <ServicesInfoComp
                    service = "Property Photography"
                    description = "Transform your spaces into stories and elevate your property's appeal with our exceptional property photography. Our team understands the art of showcasing spaces in their best light and deliver pristine still shots using our advanced camera which specialises in capturing property. We carefully review, edit and finetune all photos in-house to ensure excellent quality in the shots we take."
                    display = "none"
                    descriptionClass = "child"
                    img = {PropPhotog}
                />
                <ServicesInfoComp 
                    service = "Floor Plans"
                    description = "Unveil the blueprint of your space with our meticulously crafted floor plans. Our team leverages the cutting-edge technology of our camera to provide a detailed and clear view of your spaces layout at an affordable price."
                    display = "none"
                    descriptionClass = "child"
                    img = {Floorplan}
                />
                <ServicesInfoComp 
                    service = "Landscape Photography"
                    description = "Capture your outdoor space in a beautiful light with our landscape and outdoor photography. Whether you&#39;re showcasing the grand entrance to a home, a freshly renovated backyard or an impressive recreational facility, your space is sure to be depicted in a stunning and detailed way."
                    display = "none"
                    descriptionClass = "child"
                    img = {Landscape}
                />
            </div>
        </div>
    );
}

export default ServicesInfo;
