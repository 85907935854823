import React, {useEffect} from 'react';
import HeaderContactUs from '../components/header-props';
import HeaderBanner from '../components/header-banner';
import Footer from '../components/footer';
import ServicesInfo from '../components/services-info';

import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'G-6K4L3QFC9E'
}

TagManager.initialize(tagManagerArgs)

function Services() {
    
    window.dataLayer.push({
        event: 'pageview'
    });
    
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <HeaderContactUs 
                first_link = 'Contact Us'
                first_link_to = '/contact'
                second_link = 'Pricing'
                second_link_to = '/pricing'
            />
            <HeaderBanner 
                header = 'Services'
            />
            <ServicesInfo />
            <Footer />
        </div>
    );
}

export default Services;
