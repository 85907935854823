import React from 'react';
import '../css/services-info.css';

function ServicesInfoComp(props) {

    return (
        <div className='srvs-info-comp-container'>
            <h3>{props.service}</h3>
            <p className={props.descriptionClass}>{props.description}</p>
            <p style={{margin: "auto"}}>{props.listTitle}</p>
            {props.display === "none" ? 
                <ul className='service-list' style={{display: "none"}}>
                    <li>{props.first}</li>
                    <li>{props.second}</li>
                    <li>{props.third}</li>
                    <li>{props.fourth}</li>
                    <li>{props.fifth}</li>
                </ul>
            : 
            <ul className='service-list'>
                <li>{props.first}</li>
                <li>{props.second}</li>
                <li>{props.third}</li>
                <li>{props.fourth}</li>
                <li>{props.fifth}</li>
            </ul>}
            <div className='services-img-container'>
                <img className='services-pg' src={props.img} alt="placeholder" />
            </div>
        </div>
    );
}

export default ServicesInfoComp;
