import React from 'react';
import '../css/logo-banner.css';
import Logo from '../images/logo.png'; 

function LogoBanner() {
  
    return (
        <div className='logo-banner-container'>
            <div className='logo-banner-flex'>
                <div className='logo-banner-header'>
                    <h1>
                        Meta<span className='Lens'>Lens</span>
                    </h1>
                    <h5>
                        Property and Landscape Through The Lens
                    </h5>
                </div>
                <div className='logo-banner-img'>
                    <img src={Logo} alt="MetaLens Logo" />
                </div>
            </div>
        </div>
    )
}

export default LogoBanner;