import React, {useEffect} from 'react';
import HeaderHome from '../components/header-home';
import LogoBanner from '../components/logo-banner';
import LandscapePhoto from '../images/landscape.png'; 
import Dollhouse from '../images/dollhouse.jpg';
import Floorplan from '../images/floorplan.png';  
import ServiceAdImgL from '../components/home-service-ad-imgL';
import ServiceAdImgR from '../components/home-service-ad-imgR';
import HomeContactBanner from '../components/home-contact-banner';
import ContactForm from '../components/contact-form';
import Footer from '../components/footer';
import AboutUs from '../components/about-us';
import PropPhotog from '../images/prop-photog-2.jpeg'

import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'G-6K4L3QFC9E'
}

TagManager.initialize(tagManagerArgs)

function HomePage() {

    window.dataLayer.push({
        event: 'pageview'
    });
    
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <HeaderHome />
            <LogoBanner />
            <ServiceAdImgL 
                header='Virtual Tours'
                paragraph='Showcase your property like never before with our advanced virtual tour captures, 
                guaranteed to generate exposure and put you a step ahead of your competition.'
                img={Dollhouse}
            />
            <ServiceAdImgR 
                header='Property Photography'
                paragraph='Capture stunning 4K shots of your property. From cosy homes to sprawling 
                estates and venues - our team can beautifully display the unique features that make your 
                space stand out.'
                img={PropPhotog}
            />
            <ServiceAdImgL 
                header='Floor Plans'
                paragraph='Harness the technology of our cutting-edge camera to unveil a comprehensive 
                floor plan with accurate measurements and a complete overview of your property&#39;s layout. '
                img={Floorplan}
            />
            <ServiceAdImgR 
                header='Landscape Photography'
                paragraph='Capture the beauty and essence of outdoor spaces with our expert landscape 
                and outdoor property photography services.'
                img={LandscapePhoto}
            />
            <AboutUs />
            <HomeContactBanner />
            <ContactForm />
            <Footer />
        </div>
    );
}

export default HomePage;
