import React from 'react';
import '../css/pricing-info.css';

function PricingInfo() {
  
    return (
        <div className='pricing-info-container'>
            <div className='pricing-info-background'>
                <h3>Residential Pricing Guide </h3>
                <h5 className='subheading' style={{paddingBottom: "30px"}}>
                    All virtual tours and photography are captured through our cutting-edge 4k Matterport camera. Each service comes with complementary post-production editing and review, and free call-out for properties in Metro Melbourne. Our services are priced according to the following guide.
                    <br></br>
                    <br></br>
                    <span style={{fontWeight: "bold"}}>Disclaimer:</span> This pricing guide is not fixed and may be subject to adjustment through an official quote provided by MetaLens. Prices may vary based on individual properties and other relevant factors.
                </h5>
                <h5>Virtual Tours: </h5>
                <table className='vt-table'>
                    <tr>
                        <th>Size</th>
                        <th>Price</th>
                    </tr>
                    <tr>
                        <td>1-2 bedrooms</td>
                        <td>$250</td>
                    </tr>
                    <tr>
                        <td>3 bedrooms</td>
                        <td>$350</td>
                        
                    </tr>
                    <tr>
                        <td>4 bedrooms</td>
                        <td>$425</td>
                        
                    </tr>
                    <tr>
                        <td>5+ bedrooms</td>
                        <td>$475 + $50 per additional bedroom</td>
                    </tr>
                </table>
                <h5>Property Photography: </h5>
                <table className='pt-table'>
                    <tr>
                        <th className='call-out-table'>Size</th>
                        <th className='call-out-table'>Price</th>
                    </tr>
                    <tr>
                        <td className='call-out-table'>1-2 bedrooms</td>
                        <td className='call-out-table'>$200</td>
                    </tr>
                    <tr>
                        <td className='call-out-table'>3 bedrooms</td>
                        <td className='call-out-table'>$250</td>
                    </tr>
                    <tr>
                        <td className='call-out-table'>4 bedrooms</td>
                        <td className='call-out-table'>$300</td>
                    </tr>
                    <tr>
                        <td className='call-out-table'>5+ bedrooms</td>
                        <td className='call-out-table'>$350 + $25 per additional bedroom</td>
                    </tr>
                </table>
                <h5>Landscape Photography: </h5>
                <table className='pt-table'>
                    <tr>
                        <th className='call-out-table'>Size</th>
                        <th className='call-out-table'>Price</th>
                    </tr>
                    <tr>
                        <td className='call-out-table'>All</td>
                        <td className='call-out-table'>Property dependent<span style={{color: "#00818a"}}>&#94;</span></td>
                    </tr>
                </table>
                <h5>Call-Out Fee: </h5>
                <table className='call-out-table'>
                    <tr>
                        <th className='call-out-table'>Distance</th>
                        <th className='call-out-table'>Price</th>
                    </tr>
                    <tr>
                        <td className='call-out-table'>Metro Melbourne</td>
                        <td className='call-out-table'>Free</td>
                    </tr>
                    <tr>
                        <td className='call-out-table'>Outside of Metro Melbourne</td>
                        <td className='call-out-table'>$30</td>
                    </tr>
                </table>
                <h3>Add-ons </h3>
                <h5>Floorplans: </h5>
                <table className='fp-table'>
                    <tr>
                        <th className='call-out-table'>Size</th>
                        <th className='call-out-table'>Price</th>
                    </tr>
                    <tr>
                        <td className='call-out-table'>1-2 bedrooms</td>
                        <td className='call-out-table'>$45</td>
                    </tr>
                    <tr>
                        <td className='call-out-table'>3 bedrooms</td>
                        <td className='call-out-table'>$70</td>
                    </tr>
                    <tr>
                        <td className='call-out-table'>4 bedrooms</td>
                        <td className='call-out-table'>$90</td>
                    </tr>
                    <tr>
                        <td className='call-out-table'>5+ bedrooms</td>
                        <td className='call-out-table'>$100</td>
                    </tr>
                </table>
                <h3>Meta-Packages </h3>
                <h5 style={{marginBottom: "5px"}}>Meta Essentials Package: </h5>
                <h5 className='subheading'>Includes Virtual Tour and Property Photography.</h5>
                <table className='fp-table'>
                    <tr>
                        <th>Size</th>
                        <th>Without Package</th>
                        <th>Package Price</th>
                    </tr>
                    <tr>
                        <td>1-2 bedrooms</td>
                        <td>$450</td>
                        <td>$385</td>
                    </tr>
                    <tr>
                        <td>3 bedrooms</td>
                        <td>$600</td>
                        <td>$510</td>
                    </tr>
                    <tr>
                        <td>4 bedrooms</td>
                        <td>$725</td>
                        <td>$615</td>
                    </tr>
                    <tr>
                        <td>5+ bedrooms</td>
                        <td>$825</td>
                        <td>$680 + $50 per additional room</td>
                    </tr>
                </table>
                <h5 style={{marginBottom: "5px"}}>Meta Max Package: </h5>
                <h5 className='subheading'>Includes Virtual Tour, Property Photography, Landscape Photography and Floorplan.</h5>
                <table className='fp-table'>
                    <tr>
                        <th>Size</th>
                        <th>Without Package</th>
                        <th>Package Price</th>
                    </tr>
                    <tr>
                        <td>1-2 bedrooms</td>
                        <td>$595</td>
                        <td>$455</td>
                    </tr>
                    <tr>
                        <td>3 bedrooms</td>
                        <td>$770</td>
                        <td>$580</td>
                    </tr>
                    <tr>
                        <td>4 bedrooms</td>
                        <td>$915</td>
                        <td>$685</td>
                    </tr>
                    <tr>
                        <td>5+ bedrooms</td>
                        <td>$1025</td>
                        <td>$750 + $50 per additional room</td>
                    </tr>
                </table>
                <h3>Promotions </h3>
                <h5 style={{marginBottom: "5px", marginLeft: "0px"}}>MetaLens Referral Program: </h5>
                <h5 className='subheading'>Refer a client and both get <span style={{color: "#00818a"}}>15% off</span> your next service.</h5>
                <h6><span style={{color: "#00818a"}}>&#94;</span>Evaluated in quote</h6>
            </div>
        </div>
    )
}

export default PricingInfo;
